.LoginPage {

    margin-top: 6em;
    /* font-size: 3.4em; */

}

.LoginImgTopContainer {

    position: absolute;
    top: -77px;
    left: -141px;
}

.LoginImgTop {

    width: 12em;
}

.LoginImgBottomContainer {

    position: absolute;
    z-index: -1;
    bottom: -114px;
    right: -124px;
}

.LoginImgBottom {

    width: 12em;
}
