.fade-in-image { animation: fadeInImg 2s; }
.fade-in-txt { animation: fadeInImg 3s; }

@keyframes fadeInImg {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInTxt {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.easterEgg {

    position: absolute;
    top: 45vw;
    right: 40vw;
    width: 14vw;
    height: 20vw;
    opacity: 0.5;
    color: bisque;
    background: #00000000;
}

.easterEggPopOver > .MuiPopover-paper{

    background-color: #00000000!important;
    box-shadow: 0px 0px 0px 0px;
    font-family: 'Allura';
}

.easterEggPopOver > .MuiPopover-paper > h1{

    color: white;
    padding: 12px;
    font-family: 'Allura';
    text-shadow: 2px 2px 4px #000000!important;
}