body {
  margin: 0;
  /* font-family: Jumpstraight!important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Digital7;
  src: url(./fonts/digital-7.ttf);
}

@font-face {
  font-family: Jumpstraight;
  src: url(./fonts/Jumpstraight\ Regular.ttf);
}

@font-face {
  font-family: Allura;
  src: url(./fonts/Allura-Regular.ttf);
}
@font-face {
  font-family: Lora;
  src: url(./fonts/Lora-VariableFont_wght.ttf);
}

.outer {
  display: -webkit-inline-box;
  position: absolute;
  overflow-x: hidden;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.534)!important;
  border-radius: 0px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(3.3px);
  -webkit-backdrop-filter: blur(8.3px);
  z-index: 999;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: absolute;
  top: 170px;

  /* Whatever width you want */
}