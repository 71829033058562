.LogoutPage {

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    top: 0;
    position: absolute;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.534)!important;
    border-radius: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    z-index: 99999;
}