src/pages/RSVPPage.cssb.fade-in-image { animation: fadeInImg 3s; }
.fade-in-txt { animation: fadeInImg 3s; }

@keyframes fadeInImg {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInTxt {
    0% { opacity: 0; }
    100% { opacity: 1; }
}