.expandable {
    overflow: hidden;
    transition: all 1s ease-in-out;
    height: 0;
}

.expanded {
    transition: all 1s ease-in-out;
    height: 115px;
    overflow: hidden;
}

.calligraphyContainer {

    width: '100%';
    display: 'flex';
    justify-content: 'center';
}
.logoutContainer {
    float: right;
    width: 220px;
    text-align: right;
    padding-right: 10px;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

/* 
.fadeInText {
    opacity: 0;
    font-family: Arial;
    font-size: 4em;
    animation-name: fadeIn;
    animation-direction: forwards;
    animation-duration: 4s;
    animation-delay: 2s;
}
 */

.fadeOutText {
    opacity: 1;
    font-family: Arial;
    font-size: 4em;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 2s;
    /* 
    animation-delay: 7s;
    animation: delay 7s fadeOut 4s forwards;'
    */
}

.fadeIn {
    opacity: 0;
    font-family: Arial;
    font-size: 4em;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-name: fadeIn;
}
/* 
.fadeOut {
    font-family: Arial;
    font-size: 4em;
    animation: fadeOut 2s forwards;
}
*/
.container_row {
    display: grid;
    align-items: center;
    justify-content: center;
}
  
.layer1, .layer2 {
    grid-column: 1;
    grid-row: 1;
/*     text-align: center; */
}